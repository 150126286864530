import React, { Component } from "react";


class Blog extends Component {
    render() {

        let data = [{
            image: "blog21-1.jpg",
            title: ' Báo kinh tế & đô thị ra mắt phần mềm tòa soạn hội tụ Vpress và hệ sinh thái số',
            link: 'bao-kinh-te-&-do-thi-ra-mat-phan-mem-toa-soan-hoi-tu-vpress-va-he-sinh-thai-so',
            date: '17/10/2024',
            desc: ' Sáng 17/10/2024, Báo Kinh tế & Đô thị đã long trọng tổchức Lễ ra mắt “Tòa soạn hội tụ và hệ sinh thái số” của Báo.'
        }, {
            image: "blog23-1.png",
            title: ' 7 đặc điểm nổi bật của nền tảng tòa soạn hội tụ báo kinh tế  &  đô thị .',
            link: '7-diem-noi-bat-cua-nen-tang-tao-soan-hoi-tu-bao-kinh-te-&-do-thi',
            date: '18/10/2024',
            desc: 'Vpress là nền tảng tòa soạn hội tụ toàn diện, tận dụng tối đa các công nghệ hiện đại như trí tuệ nhân tạo, dữ liệu lớn cùng tiện ích khác, giúp các tòa soạn báo bứt phá trên hành trìnhchuyển đổi số báo chí.',
        }, {
            image: "blog21-6.png",
            title: ' Tòa soạn hội tụ và Hệ sinh thái số Báo Kinh tế & Đô thị:Tiên phong để phát triển kỷ nguyên báo chí số .',
            link: 'toa-soan-hoi-tu-va-he-sinh-thai-so-bao-kinh-te-&-do-thi-tien-phong-de-phat-trien-trong-ky-nguyen-bao-so',
            date: '17/10/2024',
            desc: ' Sáng ngày 17/10/2024, báo Kinh tế & Đô thị đã ra mắt phần mềm Tòa soạn Hội tụ Vpress đánh dấu bước đột phá trong chiến lược chuyển đổi số báo chí.'
        }, {
            image: "blog21-4.jpg",
            title: ' Các cơ quan báo chí nói gì về sự kiện ra mắt tòa soạn hội tụ Vpress của báo Kinh tế & Đô thị',
            link: 'cac-cơ-quan-bao-chi-noi-gi-ve-su-kien-ra-mat-toa-soan-hoi-tu-vpress-cua-bao-kinh-te-&-do-thi',
            date: '18/10/2024',
            desc: 'Sự kiện ra mắt tòa soạn hội tụ và hệ sinh thái số báo Kinh tế & Đô thị là một trong những dấu mốc quan trọng trong quá trình chuyển đổi số báo chí, góp phần khẳng định vị thế của báo Kinh tế & Đô thị trong làng báo chíViệt Nam.',
        }, {
            img: "https://cdnphoto.dantri.com.vn/te3G7gl08HHQm0pC0qKXvlcefOs=/thumb_w/1020/2024/07/19/lcimg-5416bf03-1f8b-4709-b65b-ab1615785cba-1721389229145.jpeg",
            title: 'Lỗi "Màn Hình Xanh" Trên Windows Gây Ảnh Hưởng Toàn Cầu',
            link: 'loi-man-hinh-xanh-tren-windows-gay-anh-huong-toan-cau',
            date: '23/7/2024',
            desc: 'Một sự cố công nghệ nghiêm trọng đã làm hàng ngàn chuyến bay bị hủy, hệ thống ngân hàng và bệnh viện ngừng hoạt động, ảnh hưởng đến hàng triệu người dùng trên toàn thế giới.'
        },
        {
            image: "img192.jpg",
            title: 'Blockchain: Bước tiến mới trong bảo mật báo chí',
            link: 'blockchain-buoc-tien-moi-trong-bao-mat-bao-chi',
            date: '23/7/2024',
            desc: 'Blockchain không chỉ được sử dụng trong Bitcoin, công nghệ này còn có nhiều tiềm năng ứng dụng trong lĩnh vực bảo mật báo chí'
        },
        {
            image: "img201.jpg",
            title: 'Big Data: Chìa khóa để hiểu độc giả tốt hơn',
            link: 'big-data-chia-khoa-de-hieu-doc-gia-tot-hon',
            date: '23/7/2024',
            desc: 'Khám phá công cụ mà các tòa soạn báo sử dụng để phân tích hành vi, sở thích và tâm lý của độc giả, từ đó tối ưu hóa nội dung, tăng cường bảo mật và minh bạch.'
        },
        {
            image: "blog15_1.png",
            title: 'Bí quyết viết chuẩn SEO dành cho dân báo chí',
            link: 'bi-quyet-viet-chuan-seo-danh-cho-dan-bao-chi',
            date: '23/5/2024',
            desc: 'Nâng tầm kỹ năng viết bài, thu hút traffic, bứt phá thứ hạng tìm kiếm với bí quyết viết chuẩn SEO dành riêng cho dân báo chí'
        },
        {
            image: "blog16_1.jpg",
            title: 'Google tăng cường cuộc đua vũ khí mới với AI',
            link: 'google-tang-cuong-cuoc-dua-vu-khi-moi-voi-ai',
            date: '23/5/2024',
            desc: 'Alphabet (Công ty mẹ của Google) ra mắt "vũ khí AI" mới tại sự kiện dành cho nhà phát triển I/O 2024: Nâng cấp chatbot, cải thiện tìm kiếm và cạnh tranh mạnh mẽ'
        }
            ,
        {
            image: "blog17_3.jpg",
            title: 'Có nên áp dụng Paywall tại Việt Nam hay không ? ',
            link: 'co-nen-ap-dung-paywall-tai-viet-nam-hay-khong',
            date: '23/5/2024',
            desc: 'Trong cơn sóng dữ dội của truyền thông toàn cầu, báo chí Việt Nam đang đứng trước ngã rẽ mang tính then chốt: Áp dụng hay từ chối mô hình Paywall - thu phí người đọc trực tuyến?'
        },];
        let DataList = data.map((val, i) => {
            return (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                    <div data-aos="fade-up" data-aos-delay={i * 90} className="blog">
                        <div className="thumb">
                            <a href={val.link}>
                                <img className="w-100" src={val.img || require(`../assets/images/blog/${val.image}`)} alt="Blog thumb" />
                            </a>
                        </div>
                        <div className="inner">
                            <h4 className="title"><a href={val.link}>{val.title}</a></h4>
                            <ul className="meta">
                                <li>{val.date}</li>
                                {/* <li>By <a    href="/">{val.postBy}</a></li> */}
                            </ul>
                            <p className="desc mt--10 mb--30">{val.desc}</p>
                            <div className="blog-btn">
                                <a className="button-link" href={val.link}>XEM THÊM</a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
        return (
            <div className="blog-area pt--120 pt_md--80 pt_sm--80" id="blog">
                <div className="container" >
                    <div data-aos="fade-up" className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--80">
                                {/* <h2>Our <span className="theme-color">Blog</span></h2> */}
                                {/* <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/> */}
                                <h2>
                                    <a className="hover-color" href={`${process.env.PUBLIC_URL}/blogs`} >TIN TỨC</a>
                                </h2>
                                <p>Nơi cập nhật các tin tức và xu hướng công nghệ mới nhất trong và ngoài nước</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {DataList}
                    </div>
                    {/* <div className="row justify-content-center mt-4">
                    <a href={`${process.env.PUBLIC_URL}/blogs`}><i class="bi bi-arrow-right"></i></a>
                </div> */}
                </div>
            </div>
        )
    }
}


export default Blog;

