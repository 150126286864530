import React, { Component } from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class BlogDetails extends Component {
    render() {
        return (
            <div className="blog-site">
                {/* Header Navbar */}
                <Navbar />

                {/* breadcrumb Area */}
                <div className="breadcrumb-area ptb--120 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h2 className="title">Lỗi "Màn Hình Xanh" Trên Windows Gây Ảnh Hưởng Toàn Cầu</h2>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Trang chủ</a></li>
                                        <li>-</li>
                                        <li>Chi tiết bài viết</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Blog Details */}
                <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-inner">
                                    <article className="single-post-details">
                                        <div className="content mt--50">
                                            <h2>Lỗi "Màn Hình Xanh" Trên Windows Gây Ảnh Hưởng Toàn Cầu</h2>
                                        </div>
                                        <div className="content mt--50">
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Một sự cố công nghệ nghiêm trọng đã làm hàng ngàn chuyến bay bị hủy, hệ thống ngân hàng và bệnh viện ngừng hoạt động, ảnh hưởng đến hàng triệu người dùng trên toàn thế giới.
                                                </h4>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Nhiều công ty và tổ chức trên thế giới đang phải đối mặt với lỗi "màn hình xanh chết chóc" (Blue Screen of Death) trên hệ điều hành Windows, gây ra hậu quả nghiêm trọng trong các lĩnh vực như hàng không, viễn thông và ngân hàng.
                                                </h4>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h3>Nguyên Nhân Sự Cố</h3>
                                                <h4 style={{ fontWeight: 'normal' }}>Nguyên nhân của sự cố này được cho là từ một bản cập nhật của CrowdStrike, một nền tảng an ninh mạng cung cấp các giải pháp bảo mật cho máy tính Windows. Công ty này xác nhận rằng sự cố không phải do lỗi bảo mật hay tấn công mạng mà xuất phát từ một bản cập nhật lỗi của Windows.
                                                    CrowdStrike cho biết: "Sự cố xảy ra khi chúng tôi triển khai bản cập nhật bị lỗi cho các máy tính chạy Microsoft Windows".

                                                </h4>
                                                {/* <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog15_2.png')} /> */}
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h3>Hậu Quả Toàn Cầu</h3>
                                                <h4 style={{ fontWeight: 'normal' }}>Sự cố đã gây ra hậu quả vô cùng nghiêm trọng cho các dịch vụ quan trọng của các quốc gia như:
                                                    Các bệnh viện và phòng khám bác sĩ gặp sự cố với hệ thống đặt lịch hẹn, trong khi các ngân hàng ở Nam Phi và New Zealand báo cáo sự cố ngừng hoạt động đối với hệ thống thanh toán hoặc trang web, ứng dụng của họ.
                                                    Một số vận động viên và khán giả đến Paris (Pháp) trước thềm Thế vận hội đã bị chậm trễ, song ban tổ chức Thế vận hội cho biết sự gián đoạn này chỉ ở mức hạn chế và không ảnh hưởng đến việc bán vé hoặc lễ rước đuốc.
                                                </h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src="https://cdnphoto.dantri.com.vn/te3G7gl08HHQm0pC0qKXvlcefOs=/thumb_w/1020/2024/07/19/lcimg-5416bf03-1f8b-4709-b65b-ab1615785cba-1721389229145.jpeg" />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Tại Hoa Kỳ, Cục Hàng không Liên bang (FAA) cho biết các hãng hàng không United, American, Delta và Allegiant đều đã ngừng hoạt động. Các hãng hàng không và đường sắt ở Anh cũng bị ảnh hưởng.</h4>

                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Ở Đức, sân bay Berlin-Brandenburg đã phải dừng các chuyến bay trong nhiều giờ, trong khi việc hạ cánh tại sân bay Zurich bị đình chỉ và các chuyến bay ở Hungary, Ý và Thổ Nhĩ Kỳ bị gián đoạn.
                                                    Tại Ấn Độ, Hồng Kông và Thái Lan, nhiều hãng hàng không buộc phải làm thủ tục check-in hành khách bằng tay.
                                                </h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src="https://cdnphoto.dantri.com.vn/avaDzt1FOGE4BPq5BbAxVMJMpFM=/thumb_w/1020/2024/07/19/widespread-1721389540861.jpeg" />
                                            </div>
                                            <div>
                                                <h3>Phản Ứng Từ Các Chuyên Gia Và Công Ty</h3>
                                                <h4 style={{ fontWeight: 'normal' }}>James Bore, chuyên gia an ninh mạng, nhận định sự cố này sẽ gây ra thiệt hại lớn: "Các bệnh viện sẽ phải vật lộn để sắp xếp các cuộc hẹn, và những người cần chăm sóc y tế có thể không nhận được sự giúp đỡ kịp thời từ các bác sĩ. Sẽ có người chết là điều không thể tránh khỏi".
                                                    Microsoft 365 đăng tải trên nền tảng mạng xã hội X rằng: "Công ty đang nỗ lực chuyển hướng lưu lượng truy cập bị ảnh hưởng sang các hệ thống thay thế để giảm thiểu tác động".
                                                    George Kurtz, Tổng Giám đốc Điều hành CrowdStrike, cũng đã đăng trên X rằng: "CrowdStrike đang tích cực làm việc với các khách hàng bị ảnh hưởng bởi lỗi trong một bản cập nhật nội dung duy nhất cho máy chủ Windows. Máy chủ Mac và Linux không bị ảnh hưởng. Vấn đề đã được xác định, cô lập và bản vá đã được triển khai".
                                                    Cổ phiếu của CrowdStrike, giao dịch trên Nasdaq, đã giảm gần 15% trong phiên giao dịch trước giờ mở cửa vào sáng thứ Sáu.
                                                </h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src="https://cdnphoto.dantri.com.vn/avaDzt1FOGE4BPq5BbAxVMJMpFM=/thumb_w/1020/2024/07/19/widespread-1721389540861.jpeg" />
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Footer */}
                <Footer horizontal="horizontal" />

            </div>
        )
    }
}

export default BlogDetails;
