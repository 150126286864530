import React , { Component } from "react";


class Download extends Component{
    render(){
        return(
            <div className={`download-area ${this.props.horizontal}`}>
                <div className="bg-overlay"/>
                <div className="container">
                    {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--55 pt--50">  
                                <h2 className="text-white">DOWNLOAD OUR <span className="theme-color">LATESTAPP</span></h2>
                                <img src={require('../assets/images/app/title-icon-4.png')} alt="App Landing"/>
                                <p className="text-white">There are many variations of passages of Lorem Ipsum available, but the majorityhave suffered alteration in some form, by injected humour,available</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <button type="submit" className="download-btn">
                                    <i className="zmdi zmdi-apple"></i>
                                    <span>Available on the <span className="large-text">App Store</span> </span>
                                </button>
                                <button type="submit" className="download-btn">
                                    <i className="zmdi zmdi-google-play"></i>
                                    <span>Available on the <span className="large-text">Play Store</span> </span>
                                </button>
                                <button type="submit" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Available on the <span className="large-text">Windows Store</span> </span>
                                </button>
                            </div>
                        </div>
                    </div> */}
                    <blockquote data-aos="fade-up" class="text-white">
                        <div className="quote-text" >
                            “Với Vpress, chúng tôi mong muốn trao quyền cho các nhà báo bằng cách cung cấp cho họ một công 
                            cụ mạnh mẽ, trực quan và dễ sử dụng. Vpress được xây dựng dựa trên nền tảng công nghệ tiên tiến 
                            nhất, giúp tối ưu hóa quy trình làm việc và nâng cao hiệu quả tác nghiệp. Chúng tôi thấu hiểu những 
                            thách thức mà các nhà báo phải đối mặt trong thời đại công nghệ số, và Vpress chính là giải pháp giúp 
                            họ vượt qua những thách thức đó để tập trung vào việc sáng tạo nội dung chất lượng cao.”
                        </div>
                        <div class="quote-white col-lg-8 offset-lg-8 mt--40" >
                            <p>Ông Lê Quang Đạo - CEO & Founder của New Solution</p>
                        </div>
                    </blockquote>
                </div>
            </div>
        )
    }
}

export default Download;







