import React, { Component } from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class BlogDetails extends Component {
    render() {
        return (
            <div className="blog-site">
                {/* Header Navbar */}
                <Navbar />

                {/* breadcrumb Area */}
                <div className="breadcrumb-area ptb--120 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h2 className="title">ChatGPT gây tranh cãi khi được sử dụng trong điện ảnh </h2>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Trang chủ</a></li>
                                        <li>-</li>
                                        <li>Chi tiết bài viết</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Blog Details */}
                <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-inner">
                                    <article className="single-post-details">
                                        <div className="content mt--50">
                                            <h2>ChatGPT gây tranh cãi khi được sử dụng trong điện ảnh </h2>
                                            <h4 style={{ fontWeight: 'normal' }}>Việc ứng dụng trí tuệ nhân tạo như ChatGPT trong ngành công nghiệp điện ảnh đã gây ra tranh cãi trong thời gian gần đây. Mặc dù nó mang lại tiềm năng cho việc tạo ra âm nhạc và nội dung, nhưng vẫn còn đó những ý kiến trái chiều</h4>
                                        </div>
                                        <div className="content mt--50">
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Vừa qua, ChatGPT đã tham gia sáng tác và phối lại một số bài hát trong bộ phim Save the Tigers, một loạt phim hài kịch tâm lý bằng tiếng Telugu, Ấn Độ được chiếu trên Disney+ Hotstar. Tuy nhiên, việc sử dụng trí tuệ nhân tạo như ChatGPT trong sản xuất âm nhạc cho phim đã gây ra nhiều ý kiến trái chiều từ phía khán giả.</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog71.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Một số người cảm thấy thất vọng vì chương trình sử dụng công nghệ AI trong ngành công nghiệp điện ảnh. Tuy nhiên ngược lại một số người khác lại ủng hộ sự minh bạch của việc sử dụng AI như ChatGPT và cho rằng khi AI được chấp nhận rộng rãi hơn, nó có thể giúp giảm tải công việc cho con người trong một số công đoạn nhất định.</h4>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Thông tin cho biết rằng ChatGPT chỉ đảm nhận một vai trò nhỏ trong sản xuất âm nhạc của phim Save the Tigers, trong khi có gần 20 bộ phận khác tham gia vào quá trình sáng tác. Một số người bày tỏ sự khó chịu khi thấy một chương trình có giá trị như Save the Tigers lại cần phải sử dụng ChatGPT và cho rằng studio có thể thuê người viết lời cho bài hát thay vì sử dụng trí tuệ nhân tạo.</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog72.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Việc ứng dụng các mô hình trí tuệ nhân tạo trong điện ảnh đang gây ra tranh cãi và đã dẫn đến cuộc đình công lớn nhất của người lao động trong ngành công nghiệp điện ảnh Hollywood vào năm ngoái. Các hãng lớn như Warner Bros và Paramount đã sử dụng AI trong sản xuất phim. Warner Bros được cho là đã hợp tác với OpenAI trong việc sử dụng ChatGPT để xây dựng một số chương trình thuộc dịch vụ phát trực tuyến Max.</h4>
                                                
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Hiện tại, trí tuệ nhân tạo được sử dụng để tăng tốc quá trình xây dựng bảng phân cảnh, thiết kế bối cảnh và chỉnh sửa cảnh quay. Nhà sản xuất cũng có thể sử dụng các bản sao kỹ thuật số của nhân viên để chỉnh sửa cảnh quay mà không cần quay lại cảnh đó. Ngoài ra, AI có thể lồng tiếng khi chỉ cần một đoạn ghi âm giọng nói diễn viên, phần mềm này có thể tạo các câu thoại y hệt với bất kỳ nội dung nào được nhập vào. Có thể nói với sự xuất hiện của các công cụ AI trong các sản phẩm chuyên nghiệp, nhiều công việc sáng tạo sẽ có thể có nguy cơ biến mất chỉ vì các hãng phim muốn tiết kiệm chi phí.
                                                </h4>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Footer */}
                <Footer horizontal="horizontal" />

            </div>
        )
    }
}

export default BlogDetails;
