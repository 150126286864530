import React, { Component } from "react";

class Navbar extends Component {
    render() {
        return (
            <div className="app-header header--transparent sticker" id="main-menu">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-sm-5 col-5">
                            <div className="logo">
                                <a href={`${process.env.PUBLIC_URL}/`}>
                                    <img src={require('../assets/images/logo/vpress_100.png')} style={{ width: '50%' }} alt="app landing" />
                                    {/* <img className="logo-2" src={require('../assets/images/logo/logo-2.png')} alt="app landing"/>
                                    <img className="logo-3" src={require('../assets/images/logo/logo-3.png')} alt="app landing"/> */}
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-8 d-none d-lg-block">
                            <div className="mainmenu-wrapper">
                                <nav>
                                    <ul className="main-menu">
                                        <li className="active"><a href={`${process.env.PUBLIC_URL}/#home`}>Trang chủ</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#about`}>Sản phẩm</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#reviews`}>Đối tác</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#blog`}>Tin tức</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#support`}>Liên hệ</a></li>
                                    </ul>
                                </nav>
                                {/* <button className="button-default button-olive" type="button">Download</button> */}
                            </div>
                        </div>
                        <div className="col-sm-7 col-7 d-block d-lg-none">
                            <div className="mobile-menu">
                                <nav>
                                    <ul>
                                        <li className="active"><a href={`${process.env.PUBLIC_URL}$/#home`}>Trang chủ</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#about`}>Sản phẩm</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#reviews`}>Đối tác</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#blog`}>Tin tức</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/#support`}>Liên hệ</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar;


