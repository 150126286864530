import React, { Component } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

class BlogDetails extends Component {
  render() {
    return (
      <div className="blog-site">
        {/* Header Navbar */}
        <Navbar />

        {/* breadcrumb Area */}
        <div
          className="breadcrumb-area ptb--120 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h2 className="title">
                    Các cơ quan báo chí nói gì về sự kiện ra mắt tòa soạn hội tụ
                    Vpress của báo Kinh tế & Đô thị
                  </h2>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Trang chủ</a>
                    </li>
                    <li>-</li>
                    <li>Chi tiết bài viết</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Blog Details */}
        <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-inner">
                  <article className="single-post-details">
                    <div className="content mt--50">
                      <h2>
                        Các cơ quan báo chí nói gì về sự kiện ra mắt tòa soạn
                        hội tụ Vpress của báo Kinh tế & Đô thị
                      </h2>
                      <p>
                        Sáng ngày 17/10/2024, Báo Kinh tế & Đô thị chính thức ra
                        mắt phần mềm Tòa soạn Hội tụ Vpress và hệ sinh thái số.
                      </p>
                    </div>
                    <div className="content mt--50">
                      <img
                        className="my-4 w-100  object-fit-cover "
                        style={{ height: "576px" }}
                        src={require("../assets/images/blog/blog21-6.png")}
                      />
                      <span
                        class="fst-italic"
                        style={{
                          display: "inline-block",
                          width: "100%",
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        Toàn cảnh Lễ ra mắt Tòa soạn hội tụ và Hệ sinh thái số
                        báo Kinh tế & Đô thị sáng 17/10/2024
                      </span>
                      <p>
                        Sự kiện nhanh chóng thu hút sự quan tâm và đánh giá cao
                        từ các cơ quan báo chí bạn trên khắp cả nước. Trong đó
                        có cả các báo Trung ương như Báo VOV, Báo Hà Nội Mới,
                        VietnamPlus, Báo Quân đội nhân dân, Báo Công an nhân
                        dân,... và các báo Tỉnh như Báo Bắc Ninh, Báo Hà Tĩnh,
                        Báo Ninh Bình, Báo Thanh Hóa, Báo Thái Nguyên,...
                      </p>
                      <p>
                        <strong> Báo VOV</strong> nêu rõ Tòa soạn hội tụ và hệ
                        sinh thái số sẽ tạo điều kiện thuận lợi để các phóng
                        viên, biên tập viên của báo có thể khai thác, biên tập
                        và phát hành thông tin một cách nhanh chóng, chính xác
                        và toàn diện nhất. Đây cũng là nền tảng để Báo Kinh tế &
                        Đô thị mở rộng phạm vi, nâng cao chất lượng phục vụ, từ
                        đó thu hút sự quan tâm, tin tưởng của độc giả không chỉ
                        trong nước mà còn trên thế giới. Đồng thời, tòa soạn hội
                        tụ và hệ sinh thái số cũng hướng tới mục tiêu xây dựng
                        một môi trường làm việc năng động, sáng tạo cho đội ngũ
                        cán bộ, công nhân viên, thúc đẩy sự phát triển bền vững
                        của Báo Kinh tế & Đô thị trong tương lai.
                      </p>
                      <p>
                        <strong>VietnamPlus</strong> cũng nhấn mạnh tầm quan
                        trọng của hệ thống Tòa soạn Hội tụ mới trong việc đáp
                        ứng nhu cầu ngày càng đa dạng của độc giả trong kỷ
                        nguyên số. Trong đó, tờ báo đặc biệt quan tâm tới các
                        tính năng được tích hợp trong hệ thống mới như văn phòng
                        điện tử với công nghệ lưu trữ đám mây tiên tiến, khả
                        năng xuất bản đa nền tảng, các ứng dụng của trí tuệ nhân
                        tạo AI và khả năng theo dõi và trao đổi công việc ngay
                        trong nền tảng.
                      </p>
                      <p>
                        <strong>Báo Hà Nội Mới</strong> nhận định Tòa soạn hội
                        tụ và hệ sinh thái số Báo Kinh tế & Đô thị không chỉ đơn
                        thuần là sự kết hợp nhiều kênh xuất bản và phân phối
                        thông tin, mà là một hệ sinh thái thông minh và linh
                        hoạt, giúp báo đạt được hiệu quả cao nhất về kinh tế báo
                        chí trong thời đại công nghệ số. Báo cũng trích dẫn lại
                        lời của ông Hà Minh Hải hấn mạnh hệ sinh thái số còn mở
                        ra cơ hội hợp tác, liên kết với các tổ chức trong và
                        ngoài nước, mở ra những mô hình kinh doanh mới và sáng
                        tạo hơn trong ngành công nghiệp truyền thông, thúc đẩy
                        việc phát triển báo chí số và kinh tế báo chí một cách
                        bền vững
                      </p>
                      <p>
                        Trong khi đó, báo<strong>Hanoi Times </strong> bày tỏ
                        niềm tự hào khi đây cũng là sự kiện đánh dấu 10 năm ra
                        mắt của kênh thông tin đối ngoại này. Với sứ mệnh giới
                        thiệu Hà Nội đến với thế giới, The Hanoi Times đã trở
                        thành nguồn thông tin đáng tin cậy, kịp thời và chính
                        xác về các sự kiện quốc tế lớn và các hoạt động kinh tế,
                        văn hóa xã hội của thủ đô. Tờ báo điện tử tiếng Anh đã
                        đóng vai trò quan trọng trong việc thu hút các nhà đầu
                        tư quốc tế, khách du lịch và phương tiện truyền thông
                        nước ngoài đến Hà Nội, giới thiệu thủ đô như một thành
                        phố hòa bình, năng động, hiện đại và thân thiện
                      </p>
                      <img
                        className="my-4 w-100  object-fit-cover "
                        style={{ height: "576px" }}
                        src={require("../assets/images/blog/blog21-4.jpg")}
                      />
                      <span
                        class="fst-italic"
                        style={{
                          display: "inline-block",
                          width: "100%",
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        Ông Hà Minh Hải - Phó Chủ tịch Ủy ban Nhân Dân thành phố
                        Hà Nội phát biểu tại buổi lễ
                      </span>
                      <p>
                        Sự kiện ra mắt tòa soạn hội tụ và hệ sinh thái số báo
                        Kinh tế & Đô thị là một trong những dấu mốc quan trọng
                        trong quá trình chuyển đổi số báo chí, góp phần khẳng
                        định vị thế của báo Kinh tế & Đô thị trong làng báo chí
                        Việt Nam. Trong xu thế chuyển đổi số báo chí mạnh mẽ, sự
                        kiện lần này thể hiện tinh thần tiên phong của tờ báo
                        thủ đô trong việc bắt kịp những công nghệ mới và cách
                        làm mới để đáp ứng nhu cầu thông tin ngày càng cao của
                        bạn đọc.
                      </p>
                      <div class="card">
                        <div class="card-body">
                          <p class="card-text">
                            Vpress là nền tảng tòa soạn hội tụ toàn diện, tận
                            dụng tối đa các công nghệ hiện đại như trí tuệ nhân
                            tạo, dữ liệu lớn cùng tiện ích khác, giúp các tòa
                            soạn báo bứt phá trên hành trình chuyển đổi số báo
                            chí.
                          </p>
                        </div>
                      </div>
                      <p>Link một số các bài viết về sự kiện:</p>
                      <ul>
                        <li>
                          <p>
                            Báo VOV:
                            <br></br>
                            <a href="https://vov.vn/xa-hoi/bao-kinh-te-do-thi-ra-mat-toa-soan-hoi-tu-va-he-sinh-thai-so-post1128960.vov">
                              https://hanoitimes.vn/kinh-te-do-thi-newspapers-converged-newsroom-to-boost-content-creation-328278.html
                            </a>
                          </p>
                        </li>
                        <li> <p>
                        Báo VietnamPlus::
                            <br></br>
                            <a href="https://vov.vn/xa-hoi/bao-kinh-te-do-thi-ra-mat-toa-soan-hoi-tu-va-he-sinh-thai-so-post1128960.vov">
                              https://hanoitimes.vn/kinh-te-do-thi-newspapers-converged-newsroom-to-boost-content-creation-328278.html
                            </a>
                          </p></li>
                        <li> <p>
                        Báo Hà Nội Mới:
                            <br></br>
                            <a href="https://vov.vn/xa-hoi/bao-kinh-te-do-thi-ra-mat-toa-soan-hoi-tu-va-he-sinh-thai-so-post1128960.vov">
                              https://hanoitimes.vn/kinh-te-do-thi-newspapers-converged-newsroom-to-boost-content-creation-328278.html
                            </a>
                          </p></li>
                        <li> <p>
                        Tờ Hanoitimes:
                            <br></br>
                            <a href="https://vov.vn/xa-hoi/bao-kinh-te-do-thi-ra-mat-toa-soan-hoi-tu-va-he-sinh-thai-so-post1128960.vov">
                              https://hanoitimes.vn/kinh-te-do-thi-newspapers-converged-newsroom-to-boost-content-creation-328278.html
                            </a>
                          </p></li>
                        <li> <p>
                        Báo Văn Hóa:
                            <br></br>
                            <a href="https://vov.vn/xa-hoi/bao-kinh-te-do-thi-ra-mat-toa-soan-hoi-tu-va-he-sinh-thai-so-post1128960.vov">
                              https://hanoitimes.vn/kinh-te-do-thi-newspapers-converged-newsroom-to-boost-content-creation-328278.html
                            </a>
                          </p></li>
                        <li> <p>
                        Báo Tuổi trẻ Thủ đô:
                            <br></br>
                            <a href="https://vov.vn/xa-hoi/bao-kinh-te-do-thi-ra-mat-toa-soan-hoi-tu-va-he-sinh-thai-so-post1128960.vov">
                              https://hanoitimes.vn/kinh-te-do-thi-newspapers-converged-newsroom-to-boost-content-creation-328278.html
                            </a>
                          </p></li>
                        <li> <p>
                        Báo Tuổi trẻ Thủ đô:
                            <br></br>
                            <a href="https://vov.vn/xa-hoi/bao-kinh-te-do-thi-ra-mat-toa-soan-hoi-tu-va-he-sinh-thai-so-post1128960.vov">
                              https://hanoitimes.vn/kinh-te-do-thi-newspapers-converged-newsroom-to-boost-content-creation-328278.html
                            </a>
                          </p></li>
                        <li> <p>
                        Truyền hình thông tấn:
                            <br></br>
                            <a href="https://vov.vn/xa-hoi/bao-kinh-te-do-thi-ra-mat-toa-soan-hoi-tu-va-he-sinh-thai-so-post1128960.vov">
                              https://hanoitimes.vn/kinh-te-do-thi-newspapers-converged-newsroom-to-boost-content-creation-328278.html
                            </a>
                          </p></li>
                      </ul>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Footer */}
        <Footer horizontal="horizontal" />
      </div>
    );
  }
}

export default BlogDetails;
