import React, { Component } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

class BlogDetails extends Component {
  render() {
    return (
      <div className="blog-site">
        {/* Header Navbar */}
        <Navbar />

        {/* breadcrumb Area */}
        <div
          className="breadcrumb-area ptb--120 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h2 className="title">
                    7 ĐIỂM NỔI BẬT CỦA NỀN TẢNG TÒA SOẠN HỘI TỤ BÁO KINH TẾ & ĐÔ
                    THỊ
                  </h2>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Trang chủ</a>
                    </li>
                    <li>-</li>
                    <li>Chi tiết bài viết</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Blog Details */}
        <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-inner">
                  <article className="single-post-details">
                    <div className="content mt--50">
                      <h2>
                        7 ĐIỂM NỔI BẬT CỦA NỀN TẢNG TÒA SOẠN HỘI TỤ BÁO KINH TẾ
                        & ĐÔ THỊ
                      </h2>
                      <h4 style={{ fontWeight: "normal" }}>
                        Sáng 17/10/2024, Báo Kinh tế & Đô thị đã chính thức ra
                        mắt Tòa soạn Hội tụ và Hệ sinh thái số. Đây không chỉ là
                        bước đột phá về mặt công nghệ, mà còn đánh dấu sự đổi
                        mới mang tính chiến lược trong việc nâng cao chất lượng
                        nội dung, trải nghiệm của độc giả và tăng nguồn thu cho
                        báo .{" "}
                      </h4>
                      {/* 1 */}
                      <img
                        className="my-4 w-100  object-fit-cover "
                        style={{ height: "576px" }}
                        src={require("../assets/images/blog/blog23-1.png")}
                      />
                      <span
                        class="fst-italic"
                        style={{
                          display: "inline-block",
                          width: "100%",
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        Hệ sinh thái Báo Kinh tế & Đô thị
                      </span>
                      <p>
                        Về bản chất, một nền tảng tòa soạn hội tụ sẽ là nơi “hội
                        tụ” tất cả các công cụ, các nền tảng, dữ liệu, quy trình
                        nghiệp vụ, quản lý tổ chức của một cơ quan báo chí.Từ
                        khâu sản xuất nội dung, quản lý dữ liệu, quản lý văn
                        phòng điện tử đến việc phát hành, quảng cáo, phân tích
                        xu hướng bạn đọc… tất cả hoạt động đều được thực hiện
                        trên 1 nền tảng duy nhất, đáp ứng tối đa các yêu cầu
                        chuyển đổi số báo chí trong thời đại mới.
                      </p>
                      <div class="card">
                        <div class="card-body">
                          <p class="card-text">
                            Ngày 6/4/2023, Thủ tướng Chính phủ phê duyệt Chiến
                            lược "Chuyển đổi số báo chí đến năm 2025, định hướng
                            đến năm 2030". Thực chất của chuyển đổi số báo chí
                            là việc ứng dụng công nghệ, làm cho hệ sinh thái báo
                            chí số được bồi đắp thêm các tính năng mới, ưu việt,
                            giúp nâng cao chất lượng, hiệu quả truyền thông.
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          textAlign: "center",
                          marginTop: "20px", 
                          marginBottom: "10px",
                        }}
                      >
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/rHnx15C-FbY"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>

                      <span
                        class="fst-italic"
                        style={{
                          display: "inline-block",
                          width: "100%",
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        Phóng sự ra mắt tòa soạn hội tụ Vpress và hệ sinh thái
                        số báo Kinh tế & Đô thị 17/10/2024
                      </span>
                      <p></p>
                      <p>
                        {" "}
                        Đâu là những điểm nổi bật của hệ thống mới này, hãy cùng
                        Vpress điểm qua trong bài blog này.
                      </p>
                      {/* danh sách */}
                      <ol>
                        <li>
                          Hội tụ đa nền tảng
                          <img
                            className="my-4 w-100  object-fit-cover "
                            style={{ height: "576px" }}
                            src={require("../assets/images/blog/blog23-2.png")}
                          />
                          <span
                            class="fst-italic"
                            style={{
                              display: "inline-block",
                              width: "100%",
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            Hội tụ đa nền tảng trong Vpress
                          </span>
                          <p>
                            Về bản chất, một nền tảng tòa soạn hội tụ sẽ là nơi
                            “hội tụ” tất cả các công cụ, các nền tảng, dữ liệu,
                            quy trình nghiệp vụ, quản lý tổ chức của một cơ quan
                            báo chí.Từ khâu sản xuất nội dung, quản lý dữ liệu,
                            quản lý văn phòng điện tử đến việc phát hành, quảng
                            cáo, phân tích xu hướng bạn đọc… tất cả hoạt động
                            đều được thực hiện trên 1 nền tảng duy nhất, đáp ứng
                            tối đa các yêu cầu chuyển đổi số báo chí trong thời
                            đại mới.
                          </p>
                        </li>
                        <li>
                          Ứng dụng trí tuệ nhân tạo
                          <img
                            className="my-4 w-100  object-fit-cover "
                            style={{ height: "576px" }}
                            src={require("../assets/images/blog/blog23-3.png")}
                          />
                          <span
                            class="fst-italic"
                            style={{
                              display: "inline-block",
                              width: "100%",
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            Ứng dụng trí tuệ nhân tạo trong Vpress
                          </span>
                          <p>
                            Trí tuệ nhân tạo không chỉ thay đổi cách chúng ta
                            tiếp cận thông tin mà thực sự đã mở ra một cuộc cách
                            mạng trong lĩnh vực báo chí và truyền thông. Với hệ
                            thống tòa soạn hội tụ của Kinh tế và Đô thị, những
                            công nghệ AI tiên tiến nhất được tích hợp mạnh mẽ,
                            tham gia ở nhiều công đoạn của quy trình sản xuất
                            nội dung cũng như quản trị tòa soạn. Bao gồm:
                          </p>
                          <ul>
                            <li>
                              AI tóm tắt văn bản tự động: Tóm tắt cả bài viết
                              thành một đoạn văn bản ngắn gọn, logic, chuẩn văn
                              phong báo chí. Công cụ này giúp hỗ trợ biên tập
                              nội dung, gợi ý phần sapo của bài viết.
                            </li>
                            <li>
                              AI chuyển text sang audio: Cho phép chuyển toàn bộ
                              nội dung bài viết sang audio, cho phép người đọc
                              nghe các bài viết thay vì phải đọc.
                            </li>
                            <li>
                              AI sáng tạo hình ảnh: AI tạo ra các hình ảnh độc
                              đáo. thu hút dùng để minh họa cho bài viết. Điều
                              này giúp các nhà báo tiết kiệm thời gian tìm kiếm
                              hình ảnh mà vẫn đảm bảo có những hình ảnh minh họa
                              phù hợp và chất lượng cao.
                            </li>
                            <li>
                              AI sáng tạo nội dung: Công cụ giúp nhà báo viết
                              các đoạn nội dung về chủ đề nhất định
                            </li>
                            <li>
                              Trợ lý AI dành cho báo chí: Trợ lý AI cung cấp các
                              gợi ý về chủ đề, nội dung, tìm kiếm thông tin với
                              ngôn ngữ phù hợp với người làm báo.
                            </li>
                            <li>
                              AI gợi ý từ khóa SEO: Gợi ý các từ khóa dành riêng
                              cho từng bài viết, giúp bài viết có lượt hiển thị
                              cao hơn trên các nền tảng tìm kiếm.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Văn phòng điện tử
                          <img
                            className="my-4 w-100  object-fit-cover "
                            style={{ height: "576px" }}
                            src={require("../assets/images/blog/blog23-4.png")}
                          />
                          <span
                            class="fst-italic"
                            style={{
                              display: "inline-block",
                              width: "100%",
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            Văn phòng điện tử trong Vpress
                          </span>
                          <p>
                            Với phương châm “văn phòng không giấy”, nền tảng mới
                            được tích hợp văn phòng điện tử với công nghệ lưu
                            trữ đám mây tiên tiến. Từ đây, các công việc như
                            quản lý và chia sẻ tài liệu, phê duyệt công văn, báo
                            cáo nội bộ, trình ký đều được số hóa và đồng bộ
                            chung trên 1 hệ thống.
                          </p>
                          <p>
                            Hệ thống mới được phát triển trên cả hai phiên bản
                            máy tính bàn và mobile, chỉ bằng một chiếc điện
                            thoại thông minh, dù ở đâu, bất kỳ lúc nào phóng
                            viên đều có thể truy cập hệ thống để viết bài, theo
                            dõi công việc và nhận thông báo của cơ quan một cách
                            dễ dàng.
                          </p>
                          <p>
                            Ngoài ra, các thành viên trong tòa soạn có thể dễ
                            dàng theo dõi, giám sát tiến độ của từng nhiệm vụ từ
                            lúc lên ý tưởng, viết, biên tập và xuất bản tin bài;
                            lãnh đạo cơ quan có thể giao việc mở cuộc họp ngay
                            trong hệ thống thông qua chat nội bộ. Hạn chế tối đa
                            việc sử dụng các công cụ bên ngoài như zalo, viber,
                            giúp thống nhất hoạt động và bảo mật thông tin.
                          </p>
                        </li>
                        <li>
                          Đột phá về giao diện
                          <img
                            className="my-4 w-100  object-fit-cover "
                            style={{ height: "576px" }}
                            src={require("../assets/images/blog/blog23-5.png")}
                          />
                          <span
                            class="fst-italic"
                            style={{
                              display: "inline-block",
                              width: "100%",
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            Đột phá về giao diện trong Vpress
                          </span>
                          <p>
                            Trên cơ sở kế thừa bản sắc của hệ sinh thái Báo Kinh
                            tế & Đô thị, giao diện mới được thiết kế khoa học,
                            thân thiện và đa dạng hình thức nhằm tối ưu hóa trải
                            nghiệm người dùng, tiếp cận gần hơn đến nhóm công
                            chúng trẻ.
                          </p>
                          <p>
                            Với sự linh hoạt của hệ thống, báo có thể tùy chỉnh
                            giao diện cho từng trang độc giả chỉ bằng thao tác
                            kéo thả đơn giản, giúp tạo nhiều trang trình bày với
                            bố cục độc đáo và có độ thẩm mỹ cao.
                          </p>
                          <p>
                            Ngoài các bài viết trình bày theo lối truyền thống,
                            giao diện mới đã đưa thêm vào các bài viết dạng
                            video, podcast ,long-form để cung cấp thông tin một
                            cách trực quan. Các bài viết báo chí dữ liệu, cập
                            nhật tin tức theo quận, huyện hay sử dụng trí tuệ
                            nhân tạo AI để tương tác, trò chuyện với bạn đọc…
                            cũng được cập nhật trên giao diện mới.
                          </p>
                        </li>
                        <li>
                          Lắng nghe và tương tác với mạng xã hội
                          <img
                            className="my-4 w-100  object-fit-cover "
                            style={{ height: "576px" }}
                            src={require("../assets/images/blog/blog23-6.png")}
                          />
                          <span
                            class="fst-italic"
                            style={{
                              display: "inline-block",
                              width: "100%",
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            Lắng nghe và tương tác với mạng xã hội trong Vpress
                          </span>
                          <p>
                            Muốn phát triển độc giả, nhất là giới trẻ, các tòa
                            soạn không thể đứng ngoài cuộc mà phải chủ động
                            chinh phục những nền tảng được quan tâm – đó chính
                            là mạng xã hội.
                          </p>
                          <p>
                            Với công cụ lắng nghe mạng xã hội, tòa soạn vừa có
                            thể phân tích các tương tác của bạn đọc với các bài
                            viết của tòa soạn, đồng thời theo dõi các chủ đề
                            được bàn luận rộng rãi trên mạng xã hội như
                            Facebook, Twitter,... .
                          </p>
                          <p>
                            Những chỉ số này là cơ sở quan trọng để đánh giá mức
                            độ hiệu quả của các nội dung đã đăng tải, đồng thời
                            giúp dự đoán và đề xuất các nội dung mới dựa trên
                            các dữ liệu thực tế.
                          </p>
                        </li>
                        <li>
                          Phát triển kinh tế báo chí
                          <img
                            className="my-4 w-100  object-fit-cover "
                            style={{ height: "576px" }}
                            src={require("../assets/images/blog/blog23-10.png")}
                          />
                          <span
                            class="fst-italic"
                            style={{
                              display: "inline-block",
                              width: "100%",
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            Phát triển kinh tế báo chí trong Vpress
                          </span>
                          <p>
                            Dưới sức cạnh tranh của các nền tảng số, quảng cáo
                            truyền thống đang bị sụt giảm nghiêm trọng, báo Kinh
                            tế & Đô thị hướng tới phát triển các nội dung chất
                            lượng cao, không chỉ tạo ra giá trị gia tăng cho độc
                            giả mà còn xây dựng cộng đồng bạn đọc VIP trung
                            thành và đẩy mạnh quảng cáo số.
                          </p>
                          <p>
                            Một trong những hướng đi sắp tới của báo là sử dụng
                            AI chuyển đổi tin, bài thành các đoạn video, audio
                            tự động, sau đó được đưa lên các nền tảng mạng xã
                            hội của Báo, nhằm tăng số lượng người theo dõi, tạo
                            ra nguồn thu quảng cáo số cho Báo. Ngoài ra, nền
                            tảng mới đã được tích hợp nhiều công cụ giúp theo
                            dõi quảng cáo hiệu quả như:Quản lý hợp đồng quảng
                            cáo, quản lý chiến dịch quảng cáo và đa dạng các
                            định dạng quảng cáo.
                          </p>
                        </li>
                        <li>
                          Hệ thống phát hành báo trực tuyến
                          <img
                            className="my-4 w-100  object-fit-cover "
                            style={{ height: "576px" }}
                            src={require("../assets/images/blog/blog23-8.png")}
                          />
                          <span
                            class="fst-italic"
                            style={{
                              display: "inline-block",
                              width: "100%",
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            Hệ thống phát hành báo trực tuyến trong Vpress
                          </span>
                          <p>
                            Hệ thống phát hành báo trực tuyến là giải pháp giúp
                            các tòa soạn dễ dàng thu phí và quản lý độc giả,
                            tiếp cận hàng triệu người dùng trên toàn quốc.
                          </p>
                          <p>
                            Độc giả có thể truy cập ngay lập tức các ấn phẩm báo
                            in chất lượng cao được phát hành dưới dạng PDF. Tòa
                            soạn có thể tùy chỉnh trang web riêng và bán trực
                            tiếp sản phẩm, dịch vụ tới độc giả. Hệ thống còn
                            giúp tòa soạn tăng cường tương tác, nhận phản hồi để
                            nâng cao chất lượng nội dung, đồng thời quản lý
                            thông tin độc giả, phân tích doanh thu và hiệu quả
                            từng ấn phẩm.
                          </p>
                          <p>
                            Đây không chỉ là một giải pháp chuyển đổi số hiệu
                            quả cho báo in, mà còn góp phần bảo vệ môi trường
                            nhờ giảm lượng phát thải báo giấy được tiêu thụ.
                          </p>
                          <img
                            className="my-4 w-100  object-fit-cover "
                            style={{ height: "576px" }}
                            src={require("../assets/images/blog/blog23-9.jpg")}
                          />
                          <span
                            class="fst-italic"
                            style={{
                              display: "inline-block",
                              width: "100%",
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            Hệ thống phát hành báo trực tuyến trong Vpress
                          </span>
                          <p>
                            Với việc cho ra mắt tòa soạn hội tụ và hệ sinh thái
                            số Báo Kinh tế & Đô thị đúng dịp kỷ niệm 70 năm ngày
                            Giải phóng Thủ đô 10/10/1954-10/10/2024 là một dấu
                            mốc rất đặc biệt đối với những người làm báo Báo
                            Kinh tế và Đô thị. Đây là bước đột phá mới để Kinh
                            tế và Đô thị trở thành cơ quan báo chí số đa phương
                            tiện, tiếp tục phát huy vai trò là cơ quan truyền
                            thông chủ lực của thành phố Hà Nội, xứng đáng là cơ
                            quan ngôn luận của UBND TP Hà Nội, diễn đàn của bạn
                            đọc trong và ngoài nước, góp phần vào sự phát triển
                            kinh tế - xã hội của Thủ đô và cả nước.
                          </p>
                          <div class="card">
                            <div class="card-body">
                              <p class="card-text">
                                Vpress là nền tảng tòa soạn hội tụ toàn diện,
                                tận dụng tối đa các công nghệ hiện đại như trí
                                tuệ nhân tạo, dữ liệu lớn cùng tiện ích khác,
                                giúp các tòa soạn báo bứt phá trên hành trình
                                chuyển đổi số báo chí.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Footer */}
        <Footer horizontal="horizontal" />
      </div>
    );
  }
}

export default BlogDetails;
