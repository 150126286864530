import { useEffect } from 'react'
// import Demo from './demo/Demo';
// import DemoTF from './demo/DemoTF';
import BlogGrid from './pages/BlogGrid';
// import HomeOlive from './pages/HomeOlive';
import BlogDetails from './pages/BlogDetails';
import BlogDetail1 from './pages/BlogDetail1';
import BlogDetail2 from './pages/BlogDetail2';
import BlogDetail3 from './pages/BlogDetail3';
import BlogDetail4 from './pages/BlogDetail4';
import BlogDetail5 from './pages/BlogDetail5';
import BlogDetail6 from './pages/BlogDetail6';
import BlogDetail7 from './pages/BlogDetail7'
import BlogDetail8 from './pages/BlogDetail8'
import BlogDetail9 from './pages/BlogDetail9'
import BlogDetail10 from './pages/BlogDetail10'
import BlogDetail11 from './pages/BlogDetail11'
import BlogDetail12 from './pages/BlogDetail12'
import BlogDetail13 from './pages/BlogDetail13'
import BlogDetail15 from './pages/BlogDetail15'
import BlogDetail16 from './pages/BlogDetail16'
import BlogDetail17 from './pages/BlogDetail17'
import BlogDetail18 from './pages/BlogDetail18'
import BlogDetail19 from './pages/BlogDetail19'
import BlogDetail20 from './pages/BlogDetail20'
import BlogDetail21 from './pages/BlogDetail21'
import BlogDetail22 from './pages/BlogDetail22'
import BlogDetail23 from './pages/BlogDetail23'
import BlogDetail24 from './pages/BlogDetail24'



// import BlogTwoColumn from './pages/BlogTwoColumn';
import HomeHorizontal from './pages/HomeHorizontal';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
    useEffect(() => {
        AOS.init({
            duration : 1000
        });
    }, []);

    return (
        <div className="App">
            <BrowserRouter basename={'/'}>
                <Switch>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Demo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/tf`} component={DemoTF}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/home-one`} component={HomeOlive}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/home-two`} component={HomeHorizontal}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeHorizontal}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blogs`} component={BlogGrid}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/blog-two-column`} component={BlogTwoColumn}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={BlogDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/thay-duoc-gi-qua-su-co-sap-facebook`} component={BlogDetail1}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/sora-con-sot-moi-trong-nganh-cong-nghiep-video`} component={BlogDetail2}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/ai-cua-alibaba-ho-bien-anh-tinh-thanh-biet-noi-va-bai-hat`} component={BlogDetail3}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/lhq-thong-qua-nghi-quyet-dau-tien-ve-tri-tue-nhan-tao-ai`} component={BlogDetail4}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/6-tips-chup-anh-chuyen-nghiep-bang-dien-thoai-cho-nguoi-lam-bao`} component={BlogDetail5}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/co-hoi-kiem-tien-nho-su-bung-no-cua-ai`} component={BlogDetail6}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/chatGPT-gay-tranh-cai-khi-duoc-su-dung-trong-dien-anh`} component={BlogDetail7}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/higgsfield-bac-thay-chuyen-dong-moi-trong-the-gioi-AI-sang-tao`} component={BlogDetail8}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/tuong-lai-cua-nganh-bao-chi-va-tri-tue-nhan-tao`} component={BlogDetail9}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/moi-lo-ngai-cua-gpt-4-vi-nguy-co-tan-cong-mang`} component={BlogDetail10}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/meta-ai-su-dung-tro-ly-ai-cho-facebook-va-messenger`} component={BlogDetail11}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/bien-anh-tinh-thanh-video-ai-voi-microsoft-vasa-1`} component={BlogDetail12}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/doc-hieu-file-fdf-nhanh-chong-bang-adobe-ai`} component={BlogDetail13}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/bi-quyet-viet-chuan-seo-danh-cho-dan-bao-chi`} component={BlogDetail15}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/google-tang-cuong-cuoc-dua-vu-khi-moi-voi-ai`} component={BlogDetail16}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/co-nen-ap-dung-paywall-tai-viet-nam-hay-khong`} component={BlogDetail17}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/loi-man-hinh-xanh-tren-windows-gay-anh-huong-toan-cau`} component={BlogDetail18}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blockchain-buoc-tien-moi-trong-bao-mat-bao-chi`} component={BlogDetail19}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/big-data-chia-khoa-de-hieu-doc-gia-tot-hon`} component={BlogDetail20}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/bao-kinh-te-&-do-thi-ra-mat-phan-mem-toa-soan-hoi-tu-vpress-va-he-sinh-thai-so`} component={BlogDetail21}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/toa-soan-hoi-tu-va-he-sinh-thai-so-bao-kinh-te-&-do-thi-tien-phong-de-phat-trien-trong-ky-nguyen-bao-so`} component={BlogDetail22}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/7-diem-noi-bat-cua-nen-tang-tao-soan-hoi-tu-bao-kinh-te-&-do-thi`} component={BlogDetail23}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/cac-cơ-quan-bao-chi-noi-gi-ve-su-kien-ra-mat-toa-soan-hoi-tu-vpress-cua-bao-kinh-te-&-do-thi`} component={BlogDetail24}/>

                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
