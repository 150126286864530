import React, { Component } from "react";



class Service extends Component{
    render(){
        let data = [
            {
                icon: 'zmdi zmdi-settings',
                title: 'Công nghệ tiên tiến',
                desc: 'Tích hợp các công nghệ tiên tiến nhất của kỷ nguyên 4.0 như trí tuệ nhân tạo (AI), dữ liệu lớn (Big Data),...'
            },

            {
                icon: 'zmdi zmdi-tap-and-play',
                title: 'Dễ sử dụng',
                desc: 'Hệ thống quản trị vô cùng trực quan, dễ dàng thao tác kể cả với những người mới.'
            },

            {
                icon: 'zmdi zmdi-favorite',
                title: 'Tư vấn tận tình',
                desc: 'Chúng tôi luôn lắng nghe để tư vấn các gói dịch vụ phù hợp với nhu cầu và nguồn kinh phí của từng tòa soạn'
            },

            {
                icon: 'zmdi zmdi-time',
                title: 'Hỗ trợ tận tâm',
                desc: 'Đội ngũ kỹ thuật viên đông đảo và giàu kinh nghiệm sẽ đảm bảo giải quyết sự cố kịp thời, nhanh chóng. '
            }
        ];
        let DataList = data.map((val, i) => {
            return(
                <div className="col-lg-3 service-column" key={i}>
                    <div data-aos="fade-up" data-aos-delay={i*500} className="single-service text-center">
                        <div className="service-icon">
                            <i className={`${val.icon}`}></i>
                        </div>
                        <h4 className="title">{val.title}</h4>
                        <p className="desc">{val.desc}</p>
                    </div>
                </div>
            )
        });

        return (
            <div>
                {/* Start Service Area */}
                <div className={`service-area ${this.props.horizontal}`}>
                    <div className="section-title text-center mb--40">
                        <h3>VÌ SAO NÊN <span className="theme-color">CHỌN CHÚNG TÔI</span></h3>
                    </div>
                    <div className="container">
                        <div className="row">
                            {DataList}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}
            </div>
        )
    }
}

export default Service;

