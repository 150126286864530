import React, { Component } from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class BlogDetails extends Component {
    render() {
        return (
            <div className="blog-site">
                {/* Header Navbar */}
                <Navbar />

                {/* breadcrumb Area */}
                <div className="breadcrumb-area ptb--120 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h2 className="title">6 “MẸO” CHỤP ẢNH CHUYÊN NGHIỆP BẰNG ĐIỆN THOẠI CHO NGƯỜI LÀM BÁO </h2>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Trang chủ</a></li>
                                        <li>-</li>
                                        <li>Chi tiết bài viết</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Blog Details */}
                <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-inner">
                                    <article className="single-post-details">
                                        <div className="content mt--50">
                                            <h2>6 "mẹo" chụp ảnh chuyên nghiệp bằng điện thoại cho người làm báo</h2>
                                            {/* <h4 style={{ fontWeight: 'normal' }}>Ngày 21/3 (giờ New York), Đại hội đồng Liên Hợp Quốc đã phê duyệt nghị quyết quan trọng nhằm thúc đẩy việc phát triển trí tuệ nhân tạo an toàn, bảo mật và đáng tin cậy, góp phần vào sự phát triển
                                                bền vững toàn cầu. Nghị quyết này được Mỹ đề xuất và nhận được sự đồng thuận của hơn 120 quốc gia, trong đó có Việt Nam.</h4> */}
                                        </div>
                                        <div className="content mt--50">
                                            <div style={{ marginBottom: '15px' }}>
                                                <h3 className="content ">1. Bố cục một phần ba (1/3)</h3>
                                                <h4 style={{ fontWeight: 'normal' }}>Trong nhiếp ảnh tỷ lệ ⅓ được xem là tỷ lệ vàng và cũng là quy tắc căn bản nhất
                                                    dành cho những người chụp ảnh chuyên nghiệp. Ở nguyên tắc này chia khung ảnh thành 9 phần bằng nhau với 2 đường
                                                    kẻ ngang và dọc. Để chụp ảnh đẹp bạn cần căn chỉnh đường chân trời ở vị trí ⅓ hoặc ⅔ của bức ảnh. Để bức ảnh có bố cục tốt và thu hút hơn, bạn nên đặt đối tượng ở 4 điểm giao nhau của các đường phân chia ảnh như hình bên dưới.</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/img1.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h3 className="content ">2. Bố cục đường hội tụ</h3>
                                                <h4 style={{ fontWeight: 'normal' }}>Các đường hội tụ được hiểu là các tia và đường thẳng trong khung hình hội tụ
                                                    về một điểm nhất định. Bạn chỉ cần tìm ra vị trí hội tụ đẹp
                                                    nhất đó chính là điểm thu hút mạnh nhất của bức ảnh. Quy tắc này
                                                    khiến bức ảnh tập trung vào chủ thể nhất định, tạo cho bức hình có một độ sâu và thu hút người xem.</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/img2.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h3 className="content ">3. Bố cục trung tâm và đối xứng</h3>
                                                <h4 style={{ fontWeight: 'normal' }}>Khi sử dụng bố cục để chụp ảnh thì quy tắc đối xứng được xem là dễ sử dụng
                                                    và mang lại hiệu quả cao nhất là khi chụp kiến trúc hay một sự vật được phản chiếu. Bố cục này đem đến một bức
                                                    ảnh đẹp hoàn hảo bằng điện thoại chẳng khác gì một nhiếp ảnh gia chuyên nghiệp.</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/img3.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h3 className="content ">4. Sử dụng các đường cong</h3>
                                                <h4 style={{ fontWeight: 'normal' }}>Áp dụng những đường cong trong bố cục bức ảnh tạo ra cảm giác chuyển động,
                                                    mềm mại. Từ đó, làm cho người xem cảm thấy đang được khám phá một hành trình thật như trong khung cảnh.</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/img4.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h3 className="content ">5. Học cách xử lý tình huống với nguồn sáng tự nhiên</h3>
                                                <h4 style={{ fontWeight: 'normal' }}>Ánh sáng trong nhiếp ảnh cơ bản đến từ 3 hướng: ánh sáng thuận chiều,
                                                    ánh sáng từ bên và ánh sáng ngược chiều. Với ánh sáng tự nhiên, chúng ta không thể kết hợp nhiều nguồn sáng
                                                    hỗn hợp giống như trong sử dụng đèn phụ ở studio. Bên cạnh đó, ánh sáng mặt trời là thứ chúng ta không thể thay
                                                    đổi tương tự công suất đèn flash. Vậy nên chúng ta phải linh động, ứng biến nhanh cho mọi tình huống chụp ảnh
                                                    ngoài trời khi không có đèn phụ can thiệp. Hiểu được các tính chất của ánh sáng tự nhiên, ta sẽ dễ dàng có những
                                                    giải pháp cụ thể trong các tình huống bị động bởi hoàn cảnh tự nhiên không được như ý. <br />
                                                    <span style={{ marginTop: '12px', display: 'inline-block' }}>Ví dụ: Nếu ngày nắng chói, hãy chụp ở nơi mát mẻ có bóng đổ. Nếu trời âm u, hãy ra đường chụp chân dung.
                                                        Trời tối thì hãy chụp ở những nơi nhiều đèn và biển hiệu,…</span>
                                                </h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/img5.jpg')} />
                                            </div>
                                            <div>
                                                <h3 className="content ">6. Học cách săn khoảnh khắc</h3>
                                                <h4 style={{ fontWeight: 'normal' }}>Khoảnh khắc là từ được nhắc rất nhiều trong thể loại ảnh đường phố. Để chụp được những khoảnh khắc thú vị, đẹp mắt, nhà báo cần có một lượng kiến thức nhất định, từ kiến thức văn hoá, kỹ năng xử lý thiết bị, đến kiến thức nhiếp ảnh cơ bản. Đôi khi, nhà báo cần phải biết chờ đợi, học đoán trước được các tình huống xảy ra để quyết sao cho cú bấm của mình ấn tượng nhất.
                                                    Nhưng khoảnh khắc trong tình huống này lại nói về cảm xúc. Bằng cách khơi gợi những câu chuyện khiến cho đối tượng của bức ảnh có những cảm xúc chân thật có thể làm cho bức ảnh trở nên sống động hơn rất nhiều.
                                                </h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/img6.jpg')} />
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Footer */}
                <Footer horizontal="horizontal" />

            </div>
        )
    }
}

export default BlogDetails;
