import React, { Component } from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class BlogDetails extends Component {
    render() {
        return (
            <div className="blog-site">
                {/* Header Navbar */}
                <Navbar />

                {/* breadcrumb Area */}
                <div className="breadcrumb-area ptb--120 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h2 className="title">Higgsfield: Bậc thầy chuyển động mới trong thế giới AI sáng tạo</h2>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Trang chủ</a></li>
                                        <li>-</li>
                                        <li>Chi tiết bài viết</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Blog Details */}
                <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-inner">
                                    <article className="single-post-details">
                                        <div className="content mt--50">
                                            <h2>Higgsfield: Bậc thầy chuyển động mới trong thế giới AI sáng tạo</h2>
                                            <h4 style={{ fontWeight: 'normal' }}>Chất lượng video từ mô hình AI tạo sinh video mới của OpenAI được đánh giá ngang ngửa sản phẩm từ các đội ngũ làm phim chuyên nghiệp.</h4>
                                        </div>
                                        <div className="content mt--50">
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Higgsfield là mô hình AI tạo sinh video tương tự Sora của OpenAI nhưng cho phép người dùng kiểm soát góc máy và chuyển động của chủ thể. Higgsfield tập trung vào sản xuất video ngắn còn Sora tập trung vào video dài.
                                                    Mô hình này được thành lập bởi Alex Mashrabov, cựu trưởng bộ phận AI sáng tạo của Snap, Higgsfield tập trung vào video ngắn cho mạng xã hội và phục vụ đa dạng nhu cầu người dùng.
                                                </h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog8.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Theo đánh giá từ Tom's Guide, một trong những thách thức lớn với các mô hình AI là khả năng tái tạo chính xác chuyển động của con người. Trong video được tạo bằng trí tuệ nhân tạo, nhân vật thường di chuyển chậm hoặc nhanh hơn thực tế, và đôi khi không logic.
                                                </h4>
                                                <video controls style={{width:'100%'}}>
                                                    <source src={require('../assets/images/blog/blog8v1.mp4')} type="video/mp4" />
                                                </video>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Nhưng với Higgsfield, câu chuyện đã khác! Higgsfield tạo ra những nhân vật với 'bước đi tự nhiên hơn mọi khi' nhờ việc kết hợp các công nghệ AI hàng đầu như ChatGPT và Gemin. Mô hình AI này không chỉ cho phép người dùng làm chủ góc máy và chuyển động một cách đáng kinh ngạc, mà còn có khả năng tạo video chuyển động mượt mà, mô phỏng thế giới vật lý vô cùng chân thực.
                                                    Theo đánh giá của quỹ đầu tư Menlo Ventures, chất lượng video từ Higgsfield có thể sánh ngang với sản phẩm từ các đội ngũ làm phim chuyên nghiệp.
                                                </h4>
                                                <video controls style={{width:'100%'}}>
                                                    <source src={require('../assets/images/blog/blog8v2.mp4')} type="video/mp4" />
                                                </video>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Higgsfield hiện đang được thử nghiệm và chưa có lịch trình thương mại hóa. Ứng dụng Diffuse dựa trên mô hình Higgsfield cũng đã cho phép người dùng iPhone tải về và chỉnh sửa video.
                                                    Higgsfield hứa hẹn sẽ trở thành công cụ sáng tạo video được ưa chuộng bởi các nhà sáng tạo nội dung, doanh nghiệp và cả người dùng cá nhân. Mô hình này khả năng cao sẽ mở ra một kỷ nguyên mới cho ngành công nghiệp video, nơi mọi người đều có thể tự do thể hiện bản thân và chia sẻ câu chuyện của mình.
                                                </h4>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Footer */}
                <Footer horizontal="horizontal" />

            </div>
        )
    }
}

export default BlogDetails;
