import React, { Component } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

class BlogDetails extends Component {
  render() {
    return (
      <div className="blog-site">
        {/* Header Navbar */}
        <Navbar />

        {/* breadcrumb Area */}
        <div
          className="breadcrumb-area ptb--120 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h2 className="title">
                    BÁO KINH TẾ & ĐÔ THỊ RA MẮT PHẦN MỀM TÒA SOẠN HỘI TỤ VPRESS
                    VÀ HỆ SINH THÁI SỐ
                  </h2>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Trang chủ</a>
                    </li>
                    <li>-</li>
                    <li>Chi tiết bài viết</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Blog Details */}
        <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-inner">
                  <article className="single-post-details">
                    <div className="content mt--50">
                      <h2>
                        BÁO KINH TẾ & ĐÔ THỊ RA MẮT PHẦN MỀM TÒA SOẠN HỘI TỤ
                        VPRESS VÀ HỆ SINH THÁI SỐ
                      </h2>
                      <h4 style={{ fontWeight: "normal" }}>
                        Sáng 17/10/2024, Báo Kinh tế & Đô thị đã long trọng tổ
                        chức Lễ ra mắt “Tòa soạn hội tụ và hệ sinh thái số” của
                        Báo. Tiêu điểm của sự kiện là ra mắt hệ thống nền tảng
                        Tòa soạn hội tụ Vpress hứa hẹn mang đến sự thay đổi toàn
                        diện cho báo trong việc nâng cao chất lượng nội dung,
                        trải nghiệm của độc giả và tăng nguồn thu cho báo.{" "}
                      </h4>
                      <p>
                        Sự kiện có sự góp mặt của ông Hà Minh Hải - Phó Chủ tịch
                        Ủy ban Nhân Dân thành phố Hà Nội cùng các cơ quan báo
                        chí bạn từ khắp các tỉnh, thành phố trên cả nước. Theo
                        Phó Giáo sư-Tiến sĩ Nguyễn Thành Lợi, Tổng biên tập Báo
                        Kinh tế & Đô thị, tòa soạn hội tụ và hệ sinh thái số Báo
                        Kinh tế & Đô thị không chỉ đơn thuần là sự kết hợp nhiều
                        kênh xuất bản và phân phối thông tin, mà là một hệ sinh
                        thái thông minh và linh hoạt, giúp báo đạt được hiệu
                      </p>
                      {/* 1 */}
                      <img
                        className="my-4 w-100  object-fit-cover "
                        style={{ height: "576px" }}
                        src={require("../assets/images/blog/blog21-1.jpg")}
                      />
                      <span class="fst-italic"style={{display: "inline-block", width: "100%", textAlign: "center" ,marginBottom: "10px"}}>
                      
                        Khoảnh khắc bấm nút ra mắt Tòa soạn hội tụ và Hệ sinh
                        thái số báo Kinh tế & Đô thị
                      </span>
                      <p>
                        Sự kiện có sự góp mặt của ông Hà Minh Hải - Phó Chủ tịch
                        Ủy ban Nhân Dân thành phố Hà Nội cùng các cơ quan báo
                        chí bạn từ khắp các tỉnh, thành phố trên cả nước. heo
                        Phó Giáo sư-Tiến sĩ Nguyễn Thành Lợi, Tổng biên tập Báo
                        Kinh tế & Đô thị, tòa soạn hội tụ và hệ sinh thái số Báo
                        Kinh tế & Đô thị không chỉ đơn thuần là sự kết hợp nhiều
                        kênh xuất bản và phân phối thông tin, mà là một hệ sinh
                        thái thông minh và linh hoạt, giúp báo đạt được hiệu.
                      </p>
                      {/* 2 */}
                      <img
                        className="my-4 w-100  object-fit-cover "
                        style={{ height: "576px" }}
                        src={require("../assets/images/blog/blog21-2.jpg")}
                      />
                      <span class="fst-italic" style={{display: "inline-block", width: "100%", textAlign: "center" ,marginBottom: "10px"}}>
                        {" "}
                        (Tổng biên tập Nguyễn Thành Lợi trình bày về những điểm
                        mới có trong hệ thống tòa soạn hội tụ)
                      </span>
                      <p>
                        Về bản chất, tòa soạn hội tụ của báo sẽ là nơi “hội tụ”
                        tất cả các công cụ, các nền tảng, dữ liệu, quy trình
                        nghiệp vụ, quản lý tổ chức của một cơ quan báo chí. Các
                        công cụ “hội tụ” trong nền tảng mới của Báo Kinh tế & Đô
                        thị sẽ bao gồm:
                      </p>

                      <ul>
                        <li>
                          Xuất bản đa nền tảng: Xuất bản bài viết trên nhiều
                          loại hình báo chí khác nhau gồm: báo in, báo điện tử,
                          chuyên trang điện tử và chia sẻ qua mạng xã hội.
                        </li>
                      </ul>
                      {/* 3 */}
                      <img
                        className="my-4 w-100  object-fit-cover "
                        style={{ height: "576px" }}
                        src={require("../assets/images/blog/blog21-3.png")}
                      />
                      <span class="fst-italic" style={{display: "inline-block", width: "100%", textAlign: "center" ,marginBottom: "10px"}}>
                       
                        Khả năng xuất bản đa nền tảng trong hệ thống tòa soạn
                        hội tụ mới của Báo
                      </span>
                      <ul>
                        <li>
                          Ứng dụng trí tuệ nhân tạo: Sử dụng tối đa các công AI
                          tiên tiến để sản xuất nội dung nhanh chóng và chất
                          lượng
                        </li>
                        <li>
                          Tòa soạn số thông minh: Xử lý các công văn, các công
                          việc được số hóa với hệ thống lưu trữ đám mây tiên
                          tiến, hướng đến “văn phòng không giấy”
                        </li>
                        <li>
                          Cải tiến giao diện: Giao diện mới của Báo được thiết
                          kế theo hướng hiện đại, trẻ trung nhất với nhiều các
                          nội dung mới nhằm tiếp cận gần hơn tới nhóm công chúng
                          trẻ.
                        </li>
                        <li>
                          Phân tích xu hướng bạn đọc: Nền tảng mới sẽ giúp Báo
                          theo dõi và phân tích xu hướng của bạn đọc qua các nền
                          tảng MXH để nắm bắt chính xác nhu cầu, sở thích bạn
                          đọc
                        </li>
                        <li>
                          Phát triển kinh tế báo chí: Công cụ mới sẽ giúp Báo
                          đẩy mạnh quảng cáo số và xây dựng cộng đồng bạn đọc
                          VIP
                        </li>
                      </ul>
                      {/* 4 */}
                      <img
                        className="my-4 w-100  object-fit-cover "
                        style={{ height: "576px" }}
                        src={require("../assets/images/blog/blog21-4.jpg")}
                      />
                      <span class="fst-italic mb-4 " style={{display: "inline-block", width: "100%", textAlign: "center" ,marginBottom: "10px"}}>
        
                        Ông Hà Minh Hải - Phó Chủ tịch Ủy ban Nhân Dân thành
                        phố Hà Nội phát biểu tại buổi lễ
                      </span>
                      <p>
                        Phát biểu tại buổi Lễ, ông Hà Minh Hải bày tỏ tin tưởng
                        rằng, với tinh thần nhân văn – tác phong chuyên nghiệp –
                        giải pháp hiện đại, và sự đổi mới mạnh mẽ trong từng
                        ngày, từng giờ, Báo Kinh tế & Đô thị sẽ tiếp tục phát
                        huy tốt vai trò và thực hiện hiệu quả sứ mệnh là cầu nối
                        quan trọng giữa thành phố, doanh nghiệp và người dân,
                        cung cấp thông tin chính xác, kịp thời và đa chiều tới
                        bạn đọc, tiếp tục có nhiều đóng góp quan trọng xây dựng
                        Thủ đô văn hiến – văn minh, hiện đại, xanh và thông minh
                        với hệ giá trị cốt lõi “Chính quyền số, chính quyền phục
                        vụ - xã hội số, xã hội niềm tin – công dân số, người dân
                        hạnh phúc”.
                      </p>
                      {/* 5 */}
                      <img
                        className="my-4 w-100  object-fit-cover "
                        style={{ height: "576px" }}
                        src={require("../assets/images/blog/blog21-5.jpg")}
                      />
                      <span class="fst-italic" style={{display: "inline-block", width: "100%", textAlign: "center" ,marginBottom: "10px"}}>
                        
                Tổng giám đốc Trần Quang Đạo - đại diện Vpress nhận hoa
                        và thư cảm ơn tại buổi lễ
                      </span>
                      <p>
                        Ông nhấn mạnh, hệ sinh thái sẽ mở ra các cơ hội hợp tác,
                        liên kết với các tổ chức trong và ngoài nước, những mô
                        hình kinh doanh mới và sáng tạo hơn trong ngành công
                        nghiệp truyền thông, thúc đẩy việc phát triển báo chí số
                        và kinh tế báo chí một cách bền vững theo tinh thần Nghị
                        quyết số 18 của Thành uỷ Hà Nội về chuyển đổi số, xây
                        dựng thành phố Hà Nội thông minh đến 2025, định hướng
                        tới năm 2030.
                      </p>
                      <p>
                        Sự kiện ra mắt Tòa soạn hội tụ và hệ sinh thái số cũng
                        là dịp Báo Kinh tế & Đô thị kỷ niệm 70 năm ngày giải
                        phóng thủ đô (10/10/1954 - 10/10/2024) và 10 năm ra mắt
                        chuyên trang tiếng Anh Hanoitimes được TP.Hà Nội chọn là
                        kênh thông tin đối ngoại chủ lực của thành phố.
                      </p>
                      {/* 6 */}
                      <img
                        className="my-4 w-100  object-fit-cover "
                        style={{ height: "576px" }}
                        src={require("../assets/images/blog/blog21-6.png")}
                      />
                      <span class="fst-italic" style={{display: "inline-block", width: "100%", textAlign: "center" ,marginBottom: "10px"}}>
                
                        Tổng giám đốc Trần Quang Đạo - đại diện Vpress nhận hoa
                        và thư cảm ơn tại buổi lễ
                      </span>
                      <p>
                        Ông nhấn mạnh, hệ sinh thái sẽ mở ra các cơ hội hợp tác,
                        liên kết với các tổ chức trong và ngoài nước, những mô
                        hình kinh doanh mới và sáng tạo hơn trong ngành công
                        nghiệp truyền thông, thúc đẩy việc phát triển báo chí số
                        và kinh tế báo chí một cách bền vững theo tinh thần Nghị
                        quyết số 18 của Thành uỷ Hà Nội về chuyển đổi số, xây
                        dựng thành phố Hà Nội thông minh đến 2025, định hướng
                        tới năm 2030.
                      </p>
                      <p>
                        Sự kiện ra mắt Tòa soạn hội tụ và hệ sinh thái số cũng
                        là dịp Báo Kinh tế & Đô thị kỷ niệm 70 năm ngày giải
                        phóng thủ đô (10/10/1954 - 10/10/2024) và 10 năm ra mắt
                        chuyên trang tiếng Anh Hanoitimes được TP.Hà Nội chọn là
                        kênh thông tin đối ngoại chủ lực của thành phố.
                      </p> 
                    </div>
                    <p class="fst-italic" style={{display: "inline-block", width: "100%", textAlign: "right" ,marginTop: "30px"}}>Nguồn: Vpress tổng hợp</p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Footer */}
        <Footer horizontal="horizontal" />
      </div>
    );
  }
}

export default BlogDetails;
