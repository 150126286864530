import React, { Component } from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class BlogDetails extends Component {
    render() {
        return (
            <div className="blog-site">
                {/* Header Navbar */}
                <Navbar />

                {/* breadcrumb Area */}
                <div className="breadcrumb-area ptb--120 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h2 className="title">Biến ảnh tĩnh thành video AI với Microsoft VASA-1</h2>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Trang chủ</a></li>
                                        <li>-</li>
                                        <li>Chi tiết bài viết</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Blog Details */}
                <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-inner">
                                    <article className="single-post-details">
                                        <div className="content mt--50">
                                            <h2>Biến ảnh tĩnh thành video AI với Microsoft VASA-1</h2>
                                            <h4 style={{ fontWeight: 'normal' }}>Microsoft đã chính thức giới thiệu VASA-1, công cụ trí tuệ nhân tạo tiên tiến có khả năng chuyển đổi ảnh tĩnh thành video chân thực với chuyển động và giọng nói một cách tự nhiên, mang lại trải nghiệm mới mẻ.</h4>
                                        </div>
                                        <div className="content mt--50">
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>VASA-1 là một công cụ trí tuệ nhân tạo mới của Microsoft, cho phép tạo ra ảnh chân dung có thể nói và hát với biểu cảm tự nhiên giống hệt con người. Microsoft cho biết VASA-1 không chỉ tạo ra cử động môi "đồng bộ một cách tinh tế" kèm theo âm thanh mà còn thể hiện thần thái của nhân vật thông qua chuyển động tự nhiên của khuôn mặt và đầu một cách chân thực nhất.</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog112.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>VASA-1 được mô tả là một "hệ thống động học cho khuôn mặt" đã được huấn luyện thông qua mô hình tạo chuyển động "tốt hơn một cách toàn diện so với các phương pháp trước đó". Công cụ này hỗ trợ xử lý ảnh chân dung với phần đầu và phần trên của thân, với các biểu cảm như vui vẻ, tức giận, ngạc nhiên hoặc nghiêm nghị.
                                                    Trong video giới thiệu trên YouTube, AI của Microsoft có khả năng khiến các ảnh do Dall-E 3 và StyleGAN2 tạo ra có thể nói chuyện một cách tự nhiên. Người dùng cũng có thể điều chỉnh thông số khuôn mặt, biểu cảm, giọng nói và nhiều chi tiết khác. VASA-1 được đánh giá có khả năng thể hiện tốt hơn so với các công cụ tương tự khác, khiến việc nhận ra sự tạo ra của AI trở nên khó khăn khi xem lần đầu.
                                                </h4>
                                                <video controls style={{width:'100%'}}>
                                                    <source src={require('../assets/images/blog/video12.mp4')} type="video/mp4" />
                                                </video>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Tuy nhiên, Microsoft từ chối tiết lộ mô hình đứng sau VASA-1 và không có kế hoạch phát hành sản phẩm riêng hoặc cung cấp API cho nhà phát triển. Họ giới thiệu VASA-1 nhằm "phô diễn một trong những khả năng của trí tuệ nhân tạo". Microsoft lưu ý rằng nghiên cứu của họ tập trung vào việc phát triển cảm xúc trực quan cho ảnh đại diện AI với mục tiêu tích cực và không nhằm tạo nội dung gây hiểu lầm hoặc lừa dối. Tuy nhiên, như các mô hình AI khác, VASA-1 vẫn có thể bị lạm dụng để mạo danh con người, điều mà Microsoft phản đối mạnh mẽ.</h4>
                                            </div>
                                            <div>
                                                <h4 style={{ fontWeight: 'normal' }}>Trong lĩnh vực trí tuệ nhân tạo, ngoài các chatbot như ChatGPT và Google Gemini, cũng có cuộc đua giữa các mô hình có khả năng tạo ra video chân thực từ ảnh tĩnh. Gần đây, Alibaba đã giới thiệu công cụ tương tự là EMO (Emotive Portrait Alive). EMO có khả năng tạo ra nét mặt, tư thế, di chuyển của lông mày, nhíu mắt và thậm chí lắc lư theo điệu nhạc.</h4>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Footer */}
                <Footer horizontal="horizontal" />

            </div>
        )
    }
}

export default BlogDetails;
